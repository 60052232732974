import { Box, Typography } from "@mui/material";

import notFoundImg from "../../assets/images/404.jpg";

const NotFound = () => {
  return (
    <Box
      p="12px"
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        background: `linear-gradient(rgba(169, 169, 169, 0.7),
        rgba(169, 169, 169, 0.7)), url(${notFoundImg}) no-repeat center / cover`,
        backgroundColor: "rgba(169, 169, 169, 0.7)",
      }}
    >
      <Typography
        textAlign="center"
        fontWeight={700}
        letterSpacing="-0.1px"
        sx={{
          fontSize: { xs: "28px", md: "64px", lg: "96px" },
          lineHeight: { xs: "36px", md: "64px", lg: "96px" },
        }}
      >
        Page Not Found
      </Typography>
      <Typography
        marginBottom="16px"
        textAlign="center"
        fontWeight={400}
        letterSpacing="-0.1px"
        sx={{
          fontSize: { xs: "18px", md: "28px", lg: "36px" },
          lineHeight: { xs: "24px", md: "36px", lg: "40px" },
        }}
      >
        The page you are looking for isn't available
      </Typography>
    </Box>
  );
};

export default NotFound;
