import SliderBackground from "./SliderBackground";
import SliderHeading from "./SliderHeading";
import SliderText from "./SliderText";
import SliderImage from "./SliderImage";
import mobileImg04 from "../../assets/images/mobile-img-04.png";

const FourthSlider = () => {
  return (
    <SliderBackground>
      <SliderHeading />
      <SliderText text="Paste or share the link to the selected recipient." />
      <SliderImage imgSrc={mobileImg04} />
    </SliderBackground>
  );
};

export default FourthSlider;
