import { Box } from "@mui/material";

type SliderBackgroundProps = {
  children: React.ReactNode;
};

const SliderBackground = ({ children }: SliderBackgroundProps) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #E5CBDD 0%, #ACDCB9 100%)",
      }}
    >
      {children}
    </Box>
  );
};

export default SliderBackground;
