import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FirstSlider from "../components/paymentSliders/FirstSlider";
import SecondSlider from "../components/paymentSliders/SecondSlider";
import ThirdSlider from "../components/paymentSliders/ThirdSlider";
import FourthSlider from "../components/paymentSliders/FourthSlider";
import FifthSlider from "../components/paymentSliders/FifthSlider";

const PaymentSlider = () => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };

  return (
    <Box
      flexBasis="50%"
      maxWidth="50%"
      sx={{ display: { xs: "none", lg: "block" } }}
    >
      <Box maxWidth="100%" overflow="hidden">
        <Slider {...settings}>
          <FirstSlider />
          <SecondSlider />
          <ThirdSlider />
          <FourthSlider />
          <FifthSlider />
        </Slider>
      </Box>
    </Box>
  );
};

export default PaymentSlider;
