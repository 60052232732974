import { Box } from "@mui/material";

type SliderImageProps = {
  imgSrc: string;
  isThirdSlider?: boolean;
};

const SliderImage = ({ imgSrc, isThirdSlider }: SliderImageProps) => {
  return (
    <Box
      mt={!isThirdSlider ? "36px" : "0"}
      width="426px"
      height="383px"
      component="img"
      src={imgSrc}
      mx="auto"
    />
  );
};

export default SliderImage;
