import { FormControl, Select, MenuItem, Box } from "@mui/material";

type MomoSelectionProps = {
  selectedValue: string;
  headerText: string;
  data: any;
  onChangeHandler: (e: any) => void;
};

const MomoSelection = ({
  selectedValue,
  onChangeHandler,
  data,
  headerText,
}: MomoSelectionProps) => {
  return (
    <>
      <Box mb={1} mt={2}>
        {headerText}
      </Box>
      <FormControl fullWidth>
        <Select
          sx={selectionStyle}
          value={selectedValue}
          onChange={onChangeHandler}
        >
          {data.map((item: Record<string, string>, index: number) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MomoSelection;

const selectionStyle = {
  marginBottom: "36px",
  background: "#fff",
  border: "none",
  "&:focus": {
    backgroundColor: "white",
  },
  "& .MuiSelect-icon": {
    color: "#29304D",
  },
  "& .MuiPaper-root": {
    border: "1px solid #ccc",
    marginTop: 8,
    borderRadius: 4,
  },
  "& .MuiList-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "pind",
    },
    "&:active": {
      backgroundColor: "red",
    },
  },
};
