import { Typography, Box } from "@mui/material";

type SliderHeadingProps = {
  isThirdSlider?: boolean;
};

const SliderHeading = ({ isThirdSlider }: SliderHeadingProps) => {
  return (
    <Typography
      mx="auto"
      mb="29px"
      width="394px"
      color="#D60C94"
      fontSize="36px"
      fontWeight={400}
      lineHeight="40px"
      textAlign="center"
      letterSpacing="-0.54px"
      textTransform="capitalize"
      pt={!isThirdSlider ? "40px" : ""}
      mt={!isThirdSlider ? "" : "38px"}
    >
      How to pay with
      <Box textTransform="none">straitPay</Box>
    </Typography>
  );
};

export default SliderHeading;
