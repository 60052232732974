import * as React from "react";
import { Box, Button, Input, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormikContext } from "formik";

import { FormValues, Bank } from "../../types/payment";

type TransferProps = {
  bank: Bank | undefined;
  isSubmitting: boolean;
};

const Transfer = (props: TransferProps) => {
  const { bank, isSubmitting } = props;

  const { submitForm, setFieldValue, values, touched, errors } =
    useFormikContext<FormValues>();

  const fileInputRef = React.useRef<any>(null);

  const handleSubmit = () => submitForm();

  const handleButtonClick = () =>
    fileInputRef.current.querySelector("input").click();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFieldValue("fileInput", file);
  };

  return (
    <Box>
      <Box>
        <Box mb="12px">Account Details</Box>

        <Grid
          p="12px"
          mb="20px"
          container
          maxWidth="400px"
          borderRadius="15px"
          sx={{ background: "#fff" }}
        >
          <Grid item xs={6} p={1}>
            <Box
              color="#61697D"
              fontWeight={400}
              lineHeight="normal"
              sx={{ fontSize: { xs: "16px" } }}
            >
              Account name
            </Box>
            <Box
              color="#0D1028"
              fontWeight={600}
              letterSpacing="-0.2px"
              lineHeight="normal"
              sx={{ fontSize: { xs: "18px" } }}
            >
              {bank?.account_name}
            </Box>
          </Grid>

          <Grid item xs={6} p={1}>
            <Box
              color="#61697D"
              fontWeight={400}
              lineHeight="normal"
              sx={{ fontSize: { xs: "16px" } }}
            >
              Account number
            </Box>
            <Box
              color="#0D1028"
              fontWeight={600}
              letterSpacing="-0.2px"
              lineHeight="normal"
              sx={{ fontSize: { xs: "18px" } }}
            >
              {bank?.bank_acc_number}
            </Box>
          </Grid>

          <Grid item xs={6} p={1}>
            <Box
              color="#61697D"
              fontWeight={400}
              lineHeight="normal"
              sx={{ fontSize: { xs: "16px" } }}
            >
              Payout location
            </Box>
            <Box
              color="#0D1028"
              fontWeight={600}
              letterSpacing="-0.2px"
              lineHeight="normal"
              sx={{ fontSize: { xs: "18px" } }}
            >
              {bank?.bank_name}
            </Box>
          </Grid>

          <Grid item xs={6} p={1}>
            <Box
              color="#61697D"
              fontWeight={400}
              lineHeight="normal"
              sx={{ fontSize: { xs: "16px" } }}
            >
              Payment country
            </Box>
            <Box
              color="#0D1028"
              fontWeight={600}
              letterSpacing="-0.2px"
              lineHeight="normal"
              sx={{ fontSize: { xs: "18px" } }}
            >
              {bank?.country}
            </Box>
          </Grid>
        </Grid>

        <Box maxWidth="400px" mb="36px">
          <Input
            type="file"
            name="fileInput"
            ref={fileInputRef}
            sx={{ display: "none" }}
            onChange={handleFileChange}
            inputProps={{
              accept: "image/png, image/jpeg, .pdf",
              maxFileSize: 20 * 1024 * 1024, // Max file size in bytes (20MB)
            }}
          />
          <Button
            sx={{
              height: "60px",
              borderRadius: "15px",
              border: "1px solid #BA3A90",
              background: "#fff",
              color: "#BA3A90",
              width: "100%",
              "&:hover": { background: "#fff", color: "#BA3A90" },
              fontSize: "17px",
              fontWeight: "700",
              textTransform: "none",
            }}
            onClick={handleButtonClick}
          >
            {values.fileInput
              ? values.fileInput.name
              : "Upload Transaction Receipt"}
          </Button>
          {errors.fileInput && touched.fileInput ? (
            <Box color="#d32f2f" mt="4px">
              <>{errors.fileInput}</>
            </Box>
          ) : (
            <Box>File format(pdf, png and jpeg only)</Box>
          )}
        </Box>

        <Button
          type="submit"
          sx={{
            height: "60px",
            borderRadius: "15px",
            background: "#BA3A90",
            color: "#fff",
            width: "100%",
            "&:hover": { background: "#BA3A90", color: "#fff" },
            fontSize: "17px",
            fontWeight: "700",
            textTransform: "none",
          }}
          onClick={handleSubmit}
          loading={isSubmitting}
          component={LoadingButton}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Transfer;
