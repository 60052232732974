import React, { useEffect, useState } from "react";
import { Box, TextField, InputLabel, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormikContext } from "formik";
import { useQuery } from "@apollo/client";

import { FormValues } from "../../types/payment";
import MomoSelection from "./MomoSelection";
import {
  GET_MOBILE_MONEY_COUNTRIES,
  GET_MOBILE_MONEY_OPERATORS,
  GET_PAYOUT_PARTNER,
} from "../../queries/payment";

type MomoProps = {
  isSubmitting: boolean;
  currency: string;
};

const Momo = ({ isSubmitting, currency }: MomoProps) => {
  const {
    submitForm,
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext<FormValues>();

  const [supportedMobileMoneyCountries, setSupportedMobileMoneyCountries] =
    useState([]);

  const [_payoutPartner, setPayoutPartner] = useState("");

  const [operators, setOperators] = useState<string[]>([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");

  // Call Formik's submitForm method to trigger form submission
  const handleSubmit = () => submitForm();

  const handlePhoneNumberChange = (value: string) => {
    setFieldValue("phoneNumber", value);
  };

  const {
    loading: payoutPartnerLoading,
    error: payoutPartnerError,
    refetch: payoutPartnerRefetch,
  } = useQuery(GET_PAYOUT_PARTNER, {
    fetchPolicy: "network-only",
    variables: {
      flow: "send_money_multi_currency",
      method: "mobile_money",
      currency: currency,
    },
    onCompleted: async (response) => {
      const { partner } = response.getPayoutPartner;

      setPayoutPartner(partner);
    },
    onError: (_error) => {},
  });

  const {
    loading: countriesLoading,
    error: countriesError,
    refetch: countriesRefetch,
  } = useQuery(GET_MOBILE_MONEY_COUNTRIES, {
    variables: {
      payout_partner: _payoutPartner, // make it dynamic
    },
    onCompleted: (_response: any) => {
      const _data = _response.getMobileMoneyCountries;
      console.log(_data);
      console.log("_data.....");
      setSupportedMobileMoneyCountries(
        _data.map((supportedCountry: any) => {
          if (supportedCountry.countryShortCode === "") {
            if (supportedCountry.name === "Benin") {
              return {
                name: supportedCountry.name,
                label: supportedCountry.name,
                currency: supportedCountry.currency,
                _id: supportedCountry._id,
                countryShortCode: "BJ",
                value: "BJ",
              };
            } else if (supportedCountry.name === "Cote D'Ivoire") {
              return {
                name: supportedCountry.name,
                label: supportedCountry.name,
                currency: supportedCountry.currency,
                _id: supportedCountry._id,
                countryShortCode: "CI",
                value: "CI",
              };
            } else if (supportedCountry.name === "Senegal") {
              return {
                name: supportedCountry.name,
                label: supportedCountry.name,
                currency: supportedCountry.currency,
                _id: supportedCountry._id,
                countryShortCode: "SN",
                value: "SN",
              };
            } else {
              return supportedCountry;
            }
          } else {
            return {
              ...supportedCountry,
              label: supportedCountry.name,
              value: supportedCountry.countryShortCode,
            };
          }
        })
      );
    },
    onError: () => {},
  });

  const {
    loading: operatorsLoading,
    error: operatorsError,
    refetch: operatorsRefetch,
  } = useQuery(GET_MOBILE_MONEY_OPERATORS, {
    variables: {
      partner: _payoutPartner, // make it dynamic
      currency: currency,
    },
    onCompleted: (_response: any) => {
      const _data = _response.getMobileMoneyOperators;

      setOperators(_data.map((item: string) => ({ value: item, label: item })));
    },
    onError: (_error) => {},
  });

  const handleSelectedCountry = (e: any) => {
    setSelectedCountry(e.target.value);
    setFieldValue("momoCountry", e.target.value);
  };
  const handleSelectedOperator = (e: any) => {
    setSelectedOperator(e.target.value);
    setFieldValue("momoOperator", e.target.value);
  };

  useEffect(() => {
    operatorsRefetch();
    setSelectedOperator("");
  }, [operatorsRefetch, selectedCountry]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "90vw",
        maxWidth: "400px",
      }}
    >
      <Box width="100%">
        <InputLabel
          htmlFor="phone-number"
          sx={{
            marginBottom: 1,
            color: "#29304D",
            fontWeight: "600",
            fontSize: { xs: "17px" },
            letterSpacing: "-0.2px",
          }}
        >
          Phone Number
        </InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          name="phoneNumber"
          id="phone-number"
          sx={{ background: "#fff" }}
          placeholder="Input your phone number and country code"
          value={values.phoneNumber}
          onBlur={() => setFieldTouched("phoneNumber")}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
        {errors.phoneNumber && touched.phoneNumber && (
          <Box color="#d32f2f" mt="4px">
            <>{errors.phoneNumber}</>
          </Box>
        )}

        <MomoSelection
          selectedValue={selectedCountry}
          onChangeHandler={handleSelectedCountry}
          data={supportedMobileMoneyCountries}
          headerText="Select Country"
        />

        {errors.momoCountry && touched.momoCountry && (
          <Box color="#d32f2f" mt="4px">
            <>{errors.momoCountry}</>
          </Box>
        )}

        {selectedCountry && (
          <MomoSelection
            selectedValue={selectedOperator}
            onChangeHandler={handleSelectedOperator}
            data={operators}
            headerText="Select Operator"
          />
        )}
        {errors.momoOperator && touched.momoOperator && (
          <Box color="#d32f2f" mt="4px">
            <>{errors.momoOperator}</>
          </Box>
        )}

        <Button
          type="submit"
          sx={{
            marginTop: "36px",
            height: "60px",
            borderRadius: "15px",
            background: "#BA3A90",
            color: "#fff",
            width: "100%",
            "&:hover": { background: "#BA3A90", color: "#fff" },
            fontSize: "17px",
            fontWeight: "700",
            textTransform: "none",
          }}
          onClick={handleSubmit}
          loading={isSubmitting}
          component={LoadingButton}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Momo;
