import SliderBackground from "./SliderBackground";
import SliderHeading from "./SliderHeading";
import SliderText from "./SliderText";
import SliderImage from "./SliderImage";
import mobileImg05 from "../../assets/images/mobile-img-05.png";

const FifthSlider = () => {
  return (
    <SliderBackground>
      <SliderHeading />
      <SliderText text="Recipient click link to proceed with payment." />
      <SliderImage imgSrc={mobileImg05} />
    </SliderBackground>
  );
};

export default FifthSlider;
