import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Aileron", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          color: "#29304D",
          background: "#F8F7FB",
        },
        h2: {
          margin: 0,
        },
        p: {
          margin: 0,
        },
      },
    },
  },
});

export default theme;
