import SliderBackground from "./SliderBackground";
import SliderHeading from "./SliderHeading";
import SliderText from "./SliderText";
import SliderImage from "./SliderImage";
import mobileImg02 from "../../assets/images/mobile-img-02.png";

const SecondSlider = () => {
  return (
    <SliderBackground>
      <SliderHeading />
      <SliderText text="Select currency, amount and create payment link." />
      <SliderImage imgSrc={mobileImg02} />
    </SliderBackground>
  );
};

export default SecondSlider;
