import { Box, TextField, MenuItem } from "@mui/material";
import { useFormikContext } from "formik";

import { CurrencyType, FormValues } from "../../types/payment";

type SenderInfoProps = {
  currencies: CurrencyType[];
  method: string;
};

const SenderInfo = ({ currencies, method }: SenderInfoProps) => {
  const { setFieldTouched, handleChange, values, errors, touched } =
    useFormikContext<FormValues>();

  console.log(values.isCard);
  console.log("values.isCard");

  return (
    <>
      <Box sx={{ display: { sm: "flex" } }}>
        <Box mb={1} width="100%" sx={{ marginRight: { sm: 1 } }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            name="firstName"
            value={values.firstName}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("firstName")}
            onChange={handleChange("firstName")}
          />

          {errors.firstName && touched.firstName && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.firstName}</>
            </Box>
          )}
        </Box>
        <Box mb={1} width="100%">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={values.lastName}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("lastName")}
            onChange={handleChange("lastName")}
          />
          {errors.lastName && touched.lastName && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.lastName}</>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: { sm: "flex" } }}>
        <Box
          mb={1}
          width="100%"
          sx={{ marginRight: { sm: values.isCard ? 1 : "" } }}
        >
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            name="email"
            value={values.email}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("email")}
            onChange={handleChange("email")}
          />
          {errors.email && touched.email && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.email}</>
            </Box>
          )}
        </Box>

        {(values.isCard === "card" || method === "card") && (
          <Box mb={1} width="100%">
            <TextField
              select
              fullWidth
              label="Select card currency"
              variant="outlined"
              sx={{ background: "#fff" }}
              name="cardCurrency"
              value={values.cardCurrency}
              onBlur={() => setFieldTouched("cardCurrency")}
              onChange={handleChange("cardCurrency")}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency._id} value={currency.currencyCode}>
                  {currency.country}
                </MenuItem>
              ))}
            </TextField>
            {errors.cardCurrency && touched.cardCurrency && (
              <Box color="#d32f2f" mt="4px">
                <>{errors.cardCurrency}</>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SenderInfo;
