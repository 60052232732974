import { Button, Box } from "@mui/material";

import { playStoreUrl, appStoreUrl } from "../../utils/constant";
import playStore from "../../assets/images/play-store.png";
import appStore from "../../assets/images/app-store.png";
import logo from "../../assets/images/logo.png";

type ReferralContentProps = {
  invalid?: boolean;
  firstName?: string;
  referralCode?: string;
};

const ReferralContent = (props: ReferralContentProps) => {
  const { invalid, firstName, referralCode } = props;

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box>
        <Box component="img" src={logo} />
      </Box>
      <Box
        textAlign="center"
        fontWeight={700}
        letterSpacing="-0.1px"
        sx={{
          margin: {
            xs: "50px 0 12px",
            md: "50px 0 28px",
            lg: "50px 0",
          },
          fontSize: { xs: "28px", md: "64px", lg: "96px" },
          lineHeight: { xs: "36px", md: "64px", lg: "96px" },
        }}
      >
        Spend & Invest <br /> Smoothly in Africa
      </Box>
      <Box
        textAlign="center"
        fontWeight={400}
        letterSpacing="-0.1px"
        sx={{
          marginBottom: { xs: "200px", md: "80px" },
          fontSize: { xs: "18px", md: "28px", lg: "36px" },
          lineHeight: { xs: "24px", md: "36px", lg: "40px" },
          maxWidth: { xs: "350px", md: "600px", lg: "900px" },
        }}
      >
        {invalid
          ? "Invalid referral code! Enjoy the good tidings straitPay offers. Download our app on Google Play Store or on App Store"
          : `Your friend ${firstName} invites you to join straitPay for easy fund transfers! Use code ${referralCode} when signing up. \nLet's spend, save, and invest together with straitPay! 💰✨`}
      </Box>

      <Box
        display="flex"
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            paddingRight: { sm: "8px" },
            marginBottom: { xs: "12px", sm: "0" },
          }}
        >
          <Button href={playStoreUrl} target="_blank" sx={{ padding: 0 }}>
            <Box component="img" src={playStore} />
          </Button>
        </Box>

        <Box sx={{ paddingLeft: { sm: "8px" } }}>
          <Button href={appStoreUrl} target="_blank" sx={{ padding: 0 }}>
            <Box component="img" src={appStore} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralContent;
