import SliderBackground from "./SliderBackground";
import SliderHeading from "./SliderHeading";
import SliderText from "./SliderText";
import SliderImage from "./SliderImage";
import mobileImg03 from "../../assets/images/mobile-img-03.png";

const ThirdSlider = () => {
  return (
    <SliderBackground>
      <SliderImage imgSrc={mobileImg03} isThirdSlider />
      <SliderHeading isThirdSlider />
      <SliderText
        isThirdSlider
        text="Copy or share created link across socials and contacts."
      />
    </SliderBackground>
  );
};

export default ThirdSlider;
