import { Typography } from "@mui/material";

type SliderTextProps = {
  text: string;
  isThirdSlider?: boolean;
};

const SliderText = ({ text, isThirdSlider }: SliderTextProps) => {
  return (
    <Typography
      color="#0E0F0C"
      fontSize="20px"
      fontWeight="400"
      lineHeight="32px"
      letterSpacing="-0.1px"
      textAlign="center"
      pb={!isThirdSlider ? "" : "51px"}
    >
      {text}
    </Typography>
  );
};

export default SliderText;
