import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { useState } from "react";

type PaymentSelectProps = {
  method: string;
  currency: string;
  onChange: (method: string) => void;
};

export const methods = [
  {
    value: "transfer",
    label: "Bank Transfer",
  },
  {
    value: "card",
    label: "Debit/Credit Card",
  },
  {
    value: "momo",
    label: "Mobile Money",
  },
];

const PaymentSelect = ({ method, onChange, currency }: PaymentSelectProps) => {
  const filteredMethods =
    currency === "XOF" ? methods.filter((_, index) => index !== 0) : methods;

  return (
    <>
      <Box mb={1} mt={2}>
        Select Payment Method
      </Box>
      <FormControl fullWidth>
        <Select
          sx={{
            marginBottom: "36px",
            background: "#fff",
            border: "none",
            "&:focus": {
              backgroundColor: "white",
            },
            "& .MuiSelect-icon": {
              color: "#29304D",
            },
            "& .MuiPaper-root": {
              border: "1px solid #ccc",
              marginTop: 8,
              borderRadius: 4,
            },
            "& .MuiList-root": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& .MuiMenuItem-root": {
              "&:hover": {
                backgroundColor: "pind",
              },
              "&:active": {
                backgroundColor: "red",
              },
            },
          }}
          value={method}
          id="payment-method-select"
          labelId="payment-method-select-label"
          onChange={(event) => onChange(event.target.value)}
        >
          {filteredMethods.map((method, index) => (
            <MenuItem key={index} value={method.value}>
              {method.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PaymentSelect;
