import { Box, Typography, Button } from "@mui/material";

type ErrorProps = {
  onTryAgain: () => void;
};

const Error = ({ onTryAgain }: ErrorProps) => {
  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography
        fontSize="28px"
        fontWeight="700"
        lineHeight="36px"
        letterSpacing="-0.1px"
        textAlign="center"
      >
        An Error Occurred!
      </Typography>
      <Typography
        fontSize="18px"
        fontWeight="400"
        lineHeight="24px"
        letterSpacing="-0.1px"
        marginBottom="15px"
        textAlign="center"
        maxWidth="370px"
      >
        Oops we’re sorry but we encountered an issue while processing your
        request.
      </Typography>
      <Button
        sx={{
          height: "60px",
          borderRadius: "15px",
          background: "#BA3A90",
          color: "#fff",
          width: "250px",
          "&:hover": { background: "#BA3A90", color: "#fff" },
          fontSize: "17px",
          fontWeight: "700",
        }}
        onClick={onTryAgain}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default Error;
