import * as React from "react";
import { Box, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import { Formik, Form, FormikState } from "formik";
import * as yup from "yup";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { useNavigate, redirect } from "react-router-dom";

import Card from "./Card";
import Transfer from "./Transfer";
import PaymentSelect from "./Select";
import Completed from "./Completed";
import Receipt from "./Receipt";
import CardDialog from "./CardDialog";
import SenderInfo from "./SenderInfo";
import uploadFileToS3 from "../../utils/uploadFileToS3";
import {
  PAYMENT_LINK_MANUAL_BANK_TO_WALLET,
  PAYMENT_LINK_CARD_TO_WALLET,
  PAYMENT_LINK_MOMO_TO_WALLET,
} from "../../queries/payment";
import { generatePaymentLinkTransferRef as genRef } from "../../utils/generateRef";
import {
  CurrencyType,
  Bank,
  FormValues,
  PaymentLinkReceiver,
} from "../../types/payment";

import userImg from "../../assets/images/user.png";
import Momo from "./Momo";
import { useEffect } from "react";

type PaymentContentProps = {
  receiver: PaymentLinkReceiver | undefined;
  bank: Bank | undefined;
  amount: string | undefined;
  receiverWalletId: number | undefined;
  paymentInfo: any | undefined;
  currencies: CurrencyType[];
};

type FormFormik = {
  setSubmitting: (isSubmitting: boolean) => void;
  resetForm: (nextState?: Partial<FormikState<FormValues>> | undefined) => void;
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  isCard: yup.string(),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  cardCurrency: yup.string().when("isCard", {
    is: "card",
    then: (schema) => schema.required("Card currency is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  // cardNumber: yup.string().when("isCard", {
  //   is: "card",
  //   then: (schema) => schema.required("Card number is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // cardExpiry: yup.string().when("isCard", {
  //   is: "card",
  //   then: (schema) => schema.required("Expiry date is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // cardCVV: yup.string().when("isCard", {
  //   is: "card",
  //   then: (schema) => schema.required("CVV is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  phoneNumber: yup.string().when("isCard", {
    is: "momo",
    then: (schema) =>
      schema
        .required("Phone Number is required")
        .matches(phoneRegExp, "Phone number is not valid"),
    otherwise: (schema) => schema.notRequired(),
  }),
  momoOperator: yup.string().when("isCard", {
    is: "momo",
    then: (schema) => schema.required("Momo Operator is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  momoCountry: yup.string().when("isCard", {
    is: "momo",
    then: (schema) => schema.required("Country is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  fileInput: yup.mixed().when("isCard", {
    is: "transfer",
    then: (schema) =>
      schema
        .required("Transaction receipt is required")
        .test(
          "fileFormat",
          "Unsupported file format(pdf, png and jpeg only)",
          (value: any) => {
            const allowedFormats = [
              "application/pdf",
              "image/png",
              "image/jpeg",
            ];
            return allowedFormats.includes(value.type);
          }
        )
        .test("fileSize", "File size is too large", (value: any) => {
          const maxSize = 20 * 1024 * 1024; // 20MB
          return value.size <= maxSize;
        }),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const PaymentContent = (props: PaymentContentProps) => {
  const navigate = useNavigate();

  const { receiver, amount, bank, receiverWalletId, currencies, paymentInfo } =
    props;

  const [continueToPay, setContinueToPay] = React.useState(false);

  console.log(bank);
  const [method, setMethod] = React.useState(!bank ? "card" : "transfer");

  const [cardTransRef, setCardTransRef] = React.useState("");
  const [openCardDialog, setCardDialogOpen] = React.useState(false);

  const [completed, setCompleted] = React.useState(false);
  const [transactionResponse, setPaymentResponse] = React.useState<any>();

  const [downloading, setDownloading] = React.useState(false);
  const captureRef = React.useRef(null);

  const [paymentLinkManualBankToWallet] = useMutation(
    PAYMENT_LINK_MANUAL_BANK_TO_WALLET
  );

  const [paymentLinkCardToWallet] = useMutation(PAYMENT_LINK_CARD_TO_WALLET);
  const [paymentLinkMomoToWallet] = useMutation(PAYMENT_LINK_MOMO_TO_WALLET);

  const handleCloseDialog = () => setCardDialogOpen(false);

  const handleCardPay = (values: FormValues, formik: FormFormik) => {
    const { setSubmitting } = formik;

    setSubmitting(true);
    paymentLinkCardToWallet({
      variables: {
        amount: amount,
        total_amount_debited: amount,
        total_amount_credited: amount,
        to_wallet: receiverWalletId,
        receiver_id: receiver?._id,
        sender_first_name: values.firstName,
        sender_last_name: values.lastName,
        sender_email: values.email,
        debit_currency: values.cardCurrency,
        credit_currency: bank?.currency || paymentInfo.currency,
        exchange_rate: "1",
        fee: "0",
        charges: "0",
        purpose: "",
      },
      onCompleted: (response) => {
        const { mode, tx_id, redirect_url } =
          response.paymentLinkCardToWalletPayment;
        setCardTransRef(tx_id);
        setSubmitting(false);

        if (mode === "pin") {
          setCardDialogOpen(true);
        } else if (mode === "redirect" && redirect_url) {
          // Redirect user to checkout URL
          window.location.replace(redirect_url);
        } else {
          toast.info("Card not supported");
        }
      },
      onError: (e) => {
        console.log(e);
        console.log("e..... 4");

        setSubmitting(false);
        toast.error("An error occurred, please try again.");
      },
    });
  };
  const handleMomoPayment = (values: FormValues, formik: FormFormik) => {
    const { setSubmitting } = formik;

    setSubmitting(true);

    paymentLinkMomoToWallet({
      variables: {
        amount: amount,
        total_amount_debited: amount,
        total_amount_credited: amount,
        to_wallet: receiverWalletId,
        receiver_id: receiver?._id,
        sender_first_name: values.firstName,
        sender_last_name: values.lastName,
        sender_email: values.email,
        debit_currency: values.cardCurrency,
        credit_currency: paymentInfo.currency,
        exchange_rate: "1",
        fee: "0",
        charges: "0",
        purpose: "",
        momo_number: values.phoneNumber,
        momo_country: values.momoCountry,
        momo_operator: values.momoOperator,
      },
      onCompleted: (data) => {
        const response = data.paymentLinkMomoToWallet;
        toast.success("Successful");
        setSubmitting(false);
        setPaymentResponse(response);
        setCompleted(true);
      },
      onError: (e) => {
        setSubmitting(false);
        if (e.message.startsWith("The phone number")) {
          toast.error(e.message);
        } else if (e.message.startsWith("Possible")) {
          toast.error(e.message);
        }

        console.log(e);
        console.log("e..... 3");
        toast.error("An error occurred, please try again...");
      },
    });
  };

  const handleTranserSubmit = async (
    values: FormValues,
    formik: FormFormik
  ) => {
    const { setSubmitting } = formik;
    try {
      setSubmitting(true);

      const file = values.fileInput as File;
      const { Location: imageUrl } = await uploadFileToS3(file);
      const reference = genRef();

      paymentLinkManualBankToWallet({
        variables: {
          tx_ref: reference,
          amount: amount,
          total_amount_debited: amount,
          total_amount_credited: amount,
          to_wallet: receiverWalletId,
          receiver_id: receiver?._id,
          sender_first_name: values.firstName,
          sender_last_name: values.lastName,
          sender_email: values.email,
          debit_currency: bank?.currency,
          credit_currency: bank?.currency,
          manual_bank_id: bank?._id,
          receipt_url: imageUrl,
          exchange_rate: "1",
          fee: "0",
          charges: "0",
          purpose: "",
        },
        onCompleted: (data) => {
          const response = data.paymentLinkManualBankToWalletTransfer;

          toast.success("Successful");
          setSubmitting(false);
          setPaymentResponse(response);
          setCompleted(true);
        },
        onError: (error) => {
          console.log(error);
          console.log("e..... 1");
          setSubmitting(false);
          toast.error("An error occurred, please try again");
        },
      });
    } catch (error) {
      console.log(error);
      console.log("e..... 2");
      setSubmitting(false);
      toast.error("An error occurred, please try again..");
    }
  };

  const handleCardCompleted = (transaction: any) => {
    setPaymentResponse(transaction);
    setCompleted(true);
  };

  const handleSubmit = (values: FormValues, formik: FormFormik) => {
    console.log(values);
    console.log("values.....");
    if (method === "card") {
      handleCardPay(values, formik);
    } else if (values.isCard === "transfer" || values.isCard === "") {
      handleTranserSubmit(values, formik);
    } else if (values.isCard === "momo") {
      handleMomoPayment(values, formik);
    } else handleCardPay(values, formik);
  };

  const handleDownload = async () => {
    if (captureRef.current) {
      try {
        setDownloading(true);
        const canvas = await html2canvas(captureRef.current);

        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "straitPay.png";
        link.click();
        setDownloading(false);
      } catch (error) {
        setDownloading(false);
        console.error("Error capturing component:", error);
      }
    }
  };

  const getFilteredCurrencies = () => {
    if (paymentInfo.currency === "USD" || paymentInfo.currency === "CAD") {
      return currencies.filter(
        (currency) => currency.currencyCode === paymentInfo.currency
      );
    }
    return currencies.filter(
      (currency) =>
        currency.currencyCode === bank?.currency || paymentInfo.currency
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flexBasis="50%"
    >
      {!completed ? (
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              mb={1}
              width="124px"
              height="124px"
              borderRadius="70px"
              component="img"
              sx={{ marginTop: { xs: "35px", lg: "0px" } }}
              src={
                receiver?.profile_picture ? receiver.profile_picture : userImg
              }
            />
            <Box
              mb="43px"
              color="#0D1028"
              fontWeight={600}
              letterSpacing="-0.2px"
              sx={{
                fontSize: { xs: "18px" },
                lineHeight: { xs: "normal" },
              }}
            >
              {receiver?.first_name} {receiver?.last_name}
            </Box>
          </Box>

          {!continueToPay && (
            <Box
              mb="25px"
              color="#0D1028"
              maxWidth="300px"
              textAlign="center"
              fontWeight={400}
              letterSpacing="-0.2px"
              sx={{
                fontSize: { xs: "18px" },
                lineHeight: { xs: "normal" },
              }}
            >
              {receiver?.first_name} has requested you pay into their straitPay
              wallet
            </Box>
          )}
          <Box
            mb={2}
            color="#BE429C"
            fontWeight={700}
            letterSpacing="-2.5px"
            sx={{ fontSize: { xs: "64px" }, lineHeight: { xs: "normal" } }}
          >
            {bank?.currency_reference.currencySymbol || paymentInfo.currency}
            {parseInt(amount as string, 10)?.toLocaleString()}
          </Box>

          {continueToPay ? (
            <Box mb="20px" maxWidth="600px">
              <Formik
                initialValues={{
                  firstName: "",
                  phoneNumber: "",
                  lastName: "",
                  email: "",
                  cardCurrency: "",
                  isCard: "",
                  fileInput: undefined,
                  cardCVV: "",
                  cardExpiry: "",
                  cardNumber: "",
                  momoOperator: "",
                  momoCountry: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur={true}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Box component={Form} noValidate autoComplete="off">
                    <SenderInfo
                      method={method}
                      currencies={getFilteredCurrencies()}
                    />

                    <PaymentSelect
                      method={method}
                      onChange={(method: string) => {
                        setFieldValue("isCard", method);
                        setMethod(method);
                      }}
                      currency={paymentInfo.currency}
                    />

                    {method === "card" ? (
                      <Card isSubmitting={isSubmitting} />
                    ) : method === "momo" ? (
                      <Momo
                        currency={paymentInfo.currency}
                        isSubmitting={isSubmitting}
                      />
                    ) : (
                      <Transfer bank={bank} isSubmitting={isSubmitting} />
                    )}
                  </Box>
                )}
              </Formik>
            </Box>
          ) : (
            <Button
              sx={{
                height: "60px",
                borderRadius: "15px",
                background: "#BA3A90",
                color: "#fff",
                width: "100%",
                maxWidth: "350px",
                "&:hover": { background: "#BA3A90", color: "#fff" },
                fontSize: "17px",
                fontWeight: "700",
                textTransform: "none",
                marginTop: { xs: "205px", lg: "85px" },
              }}
              onClick={() => setContinueToPay(true)}
            >
              Continue to Pay
            </Button>
          )}
        </>
      ) : (
        <Box maxWidth="400px">
          <Completed
            downloading={downloading}
            onDownload={handleDownload}
            transaction={transactionResponse}
          />
          <Receipt
            bank={bank}
            ref={captureRef}
            transaction={transactionResponse}
          />
        </Box>
      )}

      <CardDialog
        transRef={cardTransRef}
        open={openCardDialog}
        onClose={handleCloseDialog}
        onCompleted={handleCardCompleted}
      />
    </Box>
  );
};

export default PaymentContent;
