import { gql } from "@apollo/client";

export const GET_REFERRED_USER = gql`
  query getReferredUser($referral_code: String!) {
    getReferredUser(referral_code: $referral_code) {
      _id
      first_name
      referral_code
    }
  }
`;
