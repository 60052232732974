import { Route, Routes } from "react-router-dom";

import Referral from "../pages/Referral";
import NotFound from "../pages/NotFound/NotFound";
import Payment from "../pages/Payment";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="referral/:id" element={<Referral />} />
      <Route path="payment/:id" element={<Payment />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
