import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import ReferralContent from "./ReferralContent";
import { ReferredUser } from "../../types/referrals";
import { GET_REFERRED_USER } from "../../queries/referrals";

const Referrals = () => {
  const [referredUser, setReferredUser] = React.useState<ReferredUser>();
  const [invalidError, setInvalidError] = React.useState(false);

  const { id: referral_code } = useParams();

  const { loading, error, refetch } = useQuery(GET_REFERRED_USER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    variables: { referral_code },
    onCompleted: (data) => {
      setReferredUser(data.getReferredUser);
    },

    onError: (error) => {
      const inValidError = "invalid referral code";

      const formattedError = error
        .toString()
        .replace("ApolloError:", "")
        .toLowerCase();

      if (formattedError.includes(inValidError)) setInvalidError(true);
    },
  });

  return (
    <Box p="12px">
      {loading ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <>
              {invalidError ? (
                <ReferralContent invalid={invalidError} />
              ) : (
                <Error onTryAgain={() => refetch()} />
              )}
            </>
          ) : (
            <ReferralContent
              firstName={referredUser?.first_name}
              referralCode={referral_code}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Referrals;
