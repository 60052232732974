import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
  secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
});

const s3 = new AWS.S3();

const uploadFileToS3 = async (file: File) => {
  const params = {
    Body: file,
    Key: file.name,
    Bucket: `${process.env.REACT_APP_AWS_BUCKET_NAME}`,
  };

  try {
    return await s3.upload(params).promise();
  } catch (error) {
    throw error;
  }
};

export default uploadFileToS3;
