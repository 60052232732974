import SliderBackground from "./SliderBackground";
import SliderHeading from "./SliderHeading";
import SliderText from "./SliderText";
import SliderImage from "./SliderImage";
import mobileImg01 from "../../assets/images/mobile-img-01.png";

const FirstSlider = () => {
  return (
    <SliderBackground>
      <SliderHeading />
      <SliderText text="Select Pay Me with straitPay" />
      <SliderImage imgSrc={mobileImg01} />
    </SliderBackground>
  );
};

export default FirstSlider;
