import * as React from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, FormikState } from "formik";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import * as yup from "yup";
import { toast } from "react-toastify";

import { VALIDATE_PIN, VALIDATE_OTP } from "../../queries/payment";
import { PinValues, OtpValues } from "../../types/payment";

type CardDialogProps = {
  open: boolean;
  transRef: string;
  onClose: () => void;
  onCompleted: (transaction: any) => void;
};

type PinFormik = {
  setSubmitting: (isSubmitting: boolean) => void;
  resetForm: (nextState?: Partial<FormikState<PinValues>> | undefined) => void;
};

type OtpFormik = {
  setSubmitting: (isSubmitting: boolean) => void;
  resetForm: (nextState?: Partial<FormikState<OtpValues>> | undefined) => void;
};

const pinValidationSchema = yup.object({
  pin: yup
    .string()
    .length(4, "PIN must be 4 characters")
    .matches(/^[0-9]*$/, "PIN must be numbers")
    .required("PIN is required"),
});

const otpValidationSchema = yup.object({
  otp: yup
    .string()
    .trim()
    .min(6, "OTP must be a minimum of 6 characters")
    .required("OTP is required"),
});

const FormDialog = (props: CardDialogProps) => {
  const { open, transRef, onClose, onCompleted } = props;

  const [isOtp, setIsOtp] = React.useState(false);
  const [flwTxRef, setFlwTxRef] = React.useState("");

  const [validatePin] = useMutation(VALIDATE_PIN);
  const [validateOtp] = useMutation(VALIDATE_OTP);

  const handleValidatePin = (values: PinValues, formik: PinFormik) => {
    const { setSubmitting, resetForm } = formik;

    setSubmitting(true);

    validatePin({
      variables: {
        pin: values.pin.trim(),
        tx_ref: transRef,
      },
      onCompleted: async (response) => {
        const validatePin = response.validatePin;
        const { mode, flw_ref } = validatePin;

        setSubmitting(false);
        setFlwTxRef(flw_ref);

        if (mode === "otp") {
          // set screen as otp
          setIsOtp(true);
          resetForm();
          toast.info("PIN verified");
        } else toast.info("Card not supported");
      },
      onError: () => {
        setSubmitting(false);
        toast.error("Couldn't validate OTP! Please try again");
      },
    });
  };

  const handleValidateOtp = (values: OtpValues, formik: OtpFormik) => {
    const { setSubmitting } = formik;

    setSubmitting(true);

    validateOtp({
      variables: {
        otp: values.otp.trim(),
        tx_ref: transRef,
        flw_ref: flwTxRef,
      },
      onCompleted: (data) => {
        const response = data.validateOTP;
        setSubmitting(false);
        toast.success("Successful");
        handleClose();
        onCompleted(response);
      },
      onError: () => {
        setSubmitting(false);
        toast.error("Couldn't validate OTP! Please try again");
      },
    });
  };

  const handleClose = () => {
    if (isOtp) setIsOtp(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Card Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            top: 8,
            right: 8,
            position: "absolute",
          }}
        >
          <CloseIcon />
        </IconButton>
        <>
          {!isOtp ? (
            <>
              <Formik
                key={"pin"}
                initialValues={{ pin: "" }}
                onSubmit={handleValidatePin}
                validationSchema={pinValidationSchema}
                validateOnBlur={true}
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  handleSubmit,
                  handleChange,
                }) => {
                  return (
                    <Box
                      component={Form}
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <DialogContent>
                        <DialogContentText pb={1}>
                          Please enter your 4-digit card PIN
                        </DialogContentText>

                        <TextField
                          label="PIN"
                          fullWidth
                          value={values.pin}
                          autoFocus
                          type="password"
                          variant="standard"
                          onBlur={() => setFieldTouched("pin")}
                          onChange={handleChange("pin")}
                          inputProps={{ maxLength: 4, inputMode: "numeric" }}
                        />
                        {errors.pin && touched.pin && (
                          <Box color="#d32f2f" mt="4px">
                            <>{errors.pin}</>
                          </Box>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          component={LoadingButton}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </Box>
                  );
                }}
              </Formik>
            </>
          ) : (
            <Formik
              key={"otp"}
              initialValues={{ otp: "" }}
              onSubmit={handleValidateOtp}
              validationSchema={otpValidationSchema}
              validateOnBlur={true}
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                setFieldTouched,
                handleSubmit,
                handleChange,
              }) => {
                return (
                  <Box component={Form} onSubmit={handleSubmit}>
                    <DialogContent>
                      <DialogContentText pb={1}>
                        Please input the OTP sent to your device to complete
                        payment
                      </DialogContentText>

                      <TextField
                        label="OTP"
                        fullWidth
                        value={values.otp}
                        autoFocus
                        defaultValue=""
                        variant="standard"
                        onChange={handleChange("otp")}
                        onBlur={() => setFieldTouched("otp")}
                      />
                      {errors.otp && touched.otp && (
                        <Box color="#d32f2f" mt="4px">
                          <>{errors.otp}</>
                        </Box>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        type="submit"
                        loading={isSubmitting}
                        component={LoadingButton}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Box>
                );
              }}
            </Formik>
          )}
        </>
      </Dialog>
    </div>
  );
};

export default FormDialog;
