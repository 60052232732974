import * as React from "react";
import { Box, TextField, InputLabel, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormikContext } from "formik";

import { FormValues } from "../../types/payment";

type CardProps = {
  isSubmitting: boolean;
};

const Card = ({ isSubmitting }: CardProps) => {
  const {
    submitForm,
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext<FormValues>();

  const cardExpiryRef = React.useRef<any>(null);
  const cardCVVRef = React.useRef<any>(null);

  // Call Formik's submitForm method to trigger form submission
  const handleSubmit = () => submitForm();

  // const handleCardNumberChange = (value: string) => {
  //   let formattedValue = value.replace(/\D/g, "");
  //   formattedValue = formattedValue.replace(/(.{4})/g, "$1 ");
  //   formattedValue = formattedValue.trim();

  //   setFieldValue("cardNumber", formattedValue);
  // };

  // const handleCardExpiryDateChange = (value: string) => {
  //   let formattedValue = value.replace(/\D/g, "");
  //   formattedValue = formattedValue.replace(/^(\d{2})/, "$1/");

  //   if (formattedValue.length <= 5) setFieldValue("cardExpiry", formattedValue);

  //   if (formattedValue.length === 5 && cardCVVRef.current)
  //     cardCVVRef.current.focus();
  // };

  // const handleCardCVVChange = (value: string) => {
  //   const formattedValue = value.replace(/\D/g, "");

  //   if (formattedValue.length <= 3) setFieldValue("cardCVV", value);
  // };

  return (
    <Box
      sx={{
        display:'flex',
        width: "90vw",
        maxWidth: "400px",
      }}
    >
      <Box width={"100%"}>
        <Button
          type="submit"
          sx={{
            marginTop: "36px",
            height: "60px",
            borderRadius: "15px",
            background: "#BA3A90",
            color: "#fff",
            width: "100%",
            "&:hover": { background: "#BA3A90", color: "#fff" },
            fontSize: "17px",
            fontWeight: "700",
            textTransform: "none",
          }}
          onClick={handleSubmit}
          loading={isSubmitting}
          component={LoadingButton}
        >
          Continue..
        </Button>
      </Box>
    </Box>
  );
};

export default Card;
